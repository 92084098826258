import React from 'react';
import HomeAboutImg4 from '../../assets/images/about/about4.jpeg';
import {Link} from 'react-router-dom';

const HomeAbout = () => {
    return (
        <>
            <div className="about-area gray-bg-2 pt-200 pb-160">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-img-style-1">
                                <img src={HomeAboutImg4} alt="" />
                                <div className="about-award">
                                    <h3>Leading the Way Since 2020!</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-text-wrapper pt-30">
                                <div className="section-title mb-40">
                                    <span>We are Committed to Excellence in Inspection Services</span>
                                    <h2 className="mb-30">For over 5 years, we have researched and perfected inspection techniques and systems.</h2>
                                    <p>Our expertise ensures that every inspection is thorough and accurate, providing you with the peace of mind you need.
                                        <br /><br /> All our inspections are conducted using state-of-the-art tools and methods, making us the most reliable choice for your inspection needs. Combined with our professional team's dedication, our services are always free from oversight and delivered with utmost precision. </p>
                                </div>
                                <Link to="/contact" className="l-btn">Appointment</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeAbout;