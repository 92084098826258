import React from "react";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import Hero from "../components/hero/Hero";
import Feature from "../components/features/Features";
import HomeAbout from "../components/home-about/HomeAbout";
import WhyChoseUs from "../components/why-chose-us/WhyChoseUs";
import FunFact from "../components/fun-fact/FunFact";
import Service from "../components/sevices/Service";
import Subscribe from "../components/subscribe/Subscribe";
import Appointment from "../components/home-appointment/Appointment";
import Footer from "../common/footer/Footer";
const Home = () => {
    return (
        <>
            <HeaderBottomOne />
            <Hero />
            <Feature />
            <WhyChoseUs />
            <HomeAbout />
            <FunFact />
            <Service />
            <Subscribe />
            <Appointment />
            <Footer />
        </>
    )
}

export default Home;