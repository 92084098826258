import React from 'react';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/1.jpg';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import Subscribe from '../components/subscribe/Subscribe';
import Footer from '../common/footer/Footer';
import FunFact from '../components/fun-fact/FunFact';
import AboutExperience from '../components/home-about/AboutExperience';
import { useEffect } from 'react';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        
        <>
            {/* <HeaderTop /> */}
            <HeaderBottomOne />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="Our Company"
                description="We are available 24/7 for Inspection Services"
                currentPage="About Us"
            />
            <AboutExperience />
            <FunFact />
            <Subscribe />
            <Footer />
        </>
    )
}

export default About;

