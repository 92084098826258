import React from 'react';
import SubscribeBg3 from '../../assets/images/subscribe3.jpeg';

const Subscribe = () => {

    const handleSubmit = (e) => {
        e.preventDefault();

    const myForm = e.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        // setTimeout(()=>{handleNotification();}, 200);
        // setformStatus(!formStatus);
        window.scrollTo(0,0);
      })
      .catch((error) => alert(error));
    }

    return (
        <>
            <div className="newsletter-area pt-110 pb-110 bg-property" data-overlay="black" data-opacity="7" style={{ backgroundImage: `url(${SubscribeBg3})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="newsletter-content-wrapper text-center">
                                <h2>Get News Updates Special Event Notices And <br /> More When You Join Our Email List</h2>
                                <form name='subscribe' onSubmit={handleSubmit}>
                                    <input type="hidden" name="form-name" value="subscribe" />
                                    <input type="text" name="subscribe_user_email" placeholder="Enter Your Email" />
                                    <button type='submit' className="l-btn pl-60 pr-60 fw-light">Subscribe</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Subscribe;