import React from 'react';
import {Link} from 'react-router-dom';
import HeroImg2 from '../../assets/images/hero/2.jpeg';
import HeroImg3 from '../../assets/images/hero3.jpg';

const Hero = () => {
    
    return (
        <>
            
            <div className="hero-area height-800 bg-property d-flex align-items-center" data-overlay="black" data-opacity="7" style={{ backgroundImage: `url(${HeroImg3})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="hero-wrapper">
                                <h1>Welcome to Pine Tree Inspection.</h1>
                                <p>We are a certified company. We provide the best residential Inspections for our customers.</p>
                                <Link to="/services" className="l-btn">Our Solution</Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="hero-popup-content">
                                <img src={HeroImg2} alt="" />
                                <div className="hero-popup-video">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;