import React from 'react';
import Feature1 from '../../assets/images/icon/satisfaction-guarantee.png';
import Feature2 from '../../assets/images/icon/professional-team.png';
import Feature3 from '../../assets/images/icon/material-instrument.png';
import Feature4 from '../../assets/images/icon/experience.png';

const Feature = () => {
    return (
        <>
            <div className="features-area bg-white pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 ">
                            <div className="single-feature text-center box-shadow-3 min-height-card">
                                <div className="single-feature-icon">
                                    <img src={Feature1} alt="" />
                                </div>
                                <h5>Satisfaction Guarantee</h5>
                                <p>We stand by our work with a satisfaction guarantee. Our commitment to excellence ensures that you'll be happy with the quality of our inspections. If you're not completely satisfied, we'll make it right.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature text-center box-shadow-3 min-height-card">
                                <div className="single-feature-icon">
                                    <img src={Feature2} alt="" />
                                </div>
                                <h5>Professional Team</h5>
                                <p>Our team of certified inspectors is trained to the highest standards. With extensive experience and a keen eye for detail, our professionals provide thorough and accurate inspections every time.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature text-center box-shadow-3 min-height-card">
                                <div className="single-feature-icon">
                                    <img src={Feature3} alt="" />
                                </div>
                                <h5>Material Instrument</h5>
                                <p>We use state-of-the-art equipment to conduct our inspections. From thermal imaging cameras to moisture meters, our advanced tools help us uncover issues that might be missed by less equipped inspectors.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature text-center box-shadow-3 min-height-card">
                                <div className="single-feature-icon">
                                    <img src={Feature4} alt="" />
                                </div>
                                <h5>Years of Experience</h5>
                                <p>With a wealth of experience in the inspection industry, we have the expertise to handle any inspection challenge. Our track record speaks for itself, delivering reliable and trusted services to our clients.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Feature;