import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import WhyChooseUsBg5 from '../../assets/images/why-choose-us/bg-5.jpg';

const WhyChooseUsData = [
    {
        id: uuidv4(),
        heading: 'Free Estimates',
        description: 'Get a no-cost, no-obligation estimate for your inspection needs.',
    },
    {
        id: uuidv4(),
        heading: 'Thorough Inspections',
        description: 'Comprehensive inspections covering all aspect of your property.',
    },
    {
        id: uuidv4(),
        heading: 'Transparent Reports',
        description: 'Clear, easy-to-understand reports with no jargon or hidden fees.',
    },
    {
        id: uuidv4(),
        heading: 'Reliable Scheduling',
        description: 'Punctual and efficient inspections with minimal disruption.',
    },

];

const WhyChooseUsItem = ({ heading, description, img }) => {
    return (
        <>
            <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="single-choose-us">
                    <div className="single-choose-us-content bb-1 b-color-2">
                        <h3>{heading}</h3>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

const WhyChoseUs = () => {
    return (
        <>
            <div className="height-592 bg-property pt-110 pb-110" data-overlay="1" data-opacity="7" style={{ backgroundImage: `url(${WhyChooseUsBg5})` }}>
                <div className="container">
                    <div className="row position-relative">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>Why Choose US</span>
                                <h2 className="white-color">Experience Unmatched Expertise in<br /> Property Inspection Services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row position-relative">
                        {WhyChooseUsData.map((data) => (
                            <WhyChooseUsItem
                                key={data.id}
                                heading={data.heading}
                                description={data.description}
                                img={data.img}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyChoseUs;