import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon_landscape from '../../assets/images/service/landscape.png';
import Icon_growing from '../../assets/images/service/growing.png';
import Icon_harvest from '../../assets/images/service/harvest.png';
import Icon_flower from '../../assets/images/service/flower.png';
import Icon_tree from '../../assets/images/service/tree.png';
import Icon_rubbish from '../../assets/images/service/rubbish.png';
import { FaLongArrowAltRight } from 'react-icons/fa';
import {Link} from 'react-router-dom';

const ServiceData = [
    {
        id: uuidv4(),
        heading: 'Comprehensive Home Inspections',
        description: 'Ensure your home is safe and sound with our detailed inspections. We leave no corner unchecked.',
        img: Icon_landscape,
        serviceLink: '/contact',
    },
    {
        id: uuidv4(),
        heading: 'Commercial Property Inspections',
        description: 'From offices to warehouses, our thorough inspections protect your investment and business.',
        img: Icon_growing,
        serviceLink: '/contact',
    },
    {
        id: uuidv4(),
        heading: 'Pest and Termite Inspections',
        description: 'Detect and prevent infestations with our specialized pest and termite inspections.',
        img: Icon_harvest,
        serviceLink: '/contact',
    },
    {
        id: uuidv4(),
        heading: 'Drone Roof Inspections',
        description: 'Get a detailed aerial view of your roof with our advanced drone technology.',
        img: Icon_flower,
        serviceLink: '/contact',
    },
    {
        id: uuidv4(),
        heading: 'Specialized Inspections (e.g., mold, radon)',
        description: 'Identify hidden issues like mold and radon early with our specialized inspections.',
        img: Icon_tree,
        serviceLink: '/contact',
    },
    {
        id: uuidv4(),
        heading: 'Consultative Services',
        description: 'Get expert advice and guidance tailored to your specific inspection needs.',
        img: Icon_rubbish,
        serviceLink: '/contact',
    },

];

const ServiceItem = ({ img, heading, description, serviceLink }) => {
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-service min-height-service-card">
                    {/* <div className="service-icon">
                        <img src={img} alt="Service Icon" />
                    </div> */}
                    <h3>{heading}</h3>
                    <p>{description}</p>
                    <Link to={serviceLink}>Read More <FaLongArrowAltRight /></Link>
                </div>
            </div>
        </>
    )
}

const Service = () => {
    return (
        <>
            <div className="service-area bg-4 pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>Our Services</span>
                                <h2>Most in Demand <br/>Professional Inspection services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {ServiceData.map((data) => (
                            <ServiceItem
                                key={data.id}
                                img={data.img}
                                heading={data.heading}
                                description={data.description}
                                serviceLink={data.serviceLink}
                            />
                        ))}
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="single-service-large">
                                <h3>We provide professional <span>Inspection Services</span></h3>
                                <p>Benefit from our expertise in property inspections. We ensure thoroughness and accuracy, delivering peace of mind with every inspection. </p>
                                <Link to="/contact" className="l-btn">Appointment</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service;