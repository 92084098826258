import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import AboutExperienceImg1 from '../../assets/images/about/8.jpg';

const AboutExperienceData = [
    
    {
        id: uuidv4(),
        heading: 'Mission',
        description: 'Our mission is to deliver accurate and reliable inspections, ensuring the safety and integrity of every property we assess.',
    },

    {
        id: uuidv4(),
        heading: 'Vision',
        description: 'We envision a future where every property owner can make informed decisions based on thorough and transparent inspection reports.',
    },

    {
        id: uuidv4(),
        heading: 'Goal',
        description: 'Our goal is to set the standard in inspection excellence, utilizing advanced techniques to provide unparalleled service and peace of mind to our clients.',
    },
    
];

const AboutExperienceItem = ({ heading, description, img }) => {
    return (
        <>
            <div className="single-experience">
                <h4>{heading}</h4>
                <p>{description}</p>
            </div>
        </>
    )
}

const AboutExperience = () => {
    return (
        <>
            <div className="experience-area pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>We Have Years of Experience</span>
                                <h2>We Have Years of Experience  in <br /> Inspection Services</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 offset-xl-2 offset-lg-2 offset-md-2">
                            {AboutExperienceData.map((data) => (
                                <AboutExperienceItem
                                    key={data.id}
                                    heading={data.heading}
                                    description={data.description}
                                />
                            ))}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-lg-6 pl-85 pr-0">
                            <div className="experience-img">
                                <img src={AboutExperienceImg1} alt="" />
                                <div className="experience-small-block">
                                    <h5> Leading the Way Since 2020!</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutExperience;