import React from 'react';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/1.jpg';
import Service from '../components/sevices/Service';
import Cta from '../components/cta/Cta';

import Subscribe from '../components/subscribe/Subscribe';
import Footer from '../common/footer/Footer';
import WhyChoseUs from '../components/why-chose-us/WhyChoseUs';
import HomeAbout from '../components/home-about/HomeAbout';

import {useEffect} from 'react';


const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* <HeaderTop /> */}
            <HeaderBottomOne />
            <Breadcrumb 
            breadcrumbBg={breadcrumbBg} 
            heading="Services" 
            description="We are available 24/7 for Inspection Services" 
            currentPage="Services" />
            <Service />
            <Cta />
            <WhyChoseUs />
            <HomeAbout />
            <Subscribe />
            <Footer />
        </>
    )
}

export default Services;