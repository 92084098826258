import React from 'react';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/1.jpg';
import ContactForm from '../components/contact/ContactForm';
import Subscribe from '../components/subscribe/Subscribe';
import Footer from '../common/footer/Footer';
import { useEffect } from 'react';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* <HeaderTop /> */}
            <HeaderBottomOne />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="Contact Us"
                description="We are available 24/7 for Inspection Services"
                currentPage="Contact Us" 
            />
            <ContactForm />
            {/* <Cta /> */}
            {/* <ContactMap /> */}
            <Subscribe />
            <Footer />
        </>
    )
}

export default Contact;